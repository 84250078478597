// import custom scss
@import './custom-primeng';
@import './base';
@import './responsive-utility.scss';

.l-container {
  padding-right: 6.7rem;
  padding-left: 6.7rem;
  font-family: Helvetica, sans-serif !important;
  @media screen and (max-width: 1020px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}