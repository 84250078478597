/* @import 'node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css'; */
@import '../scss/_base.scss';
/* header starts */

/* var(--l-grey-900) */
svg{
  color: var(--l-grey-900)!important;
}

.primary-button{
  cursor: pointer !important;
  color: var(--l-primary-tenant-button-text) !important;
  background-color: var(--l-primary-tenant-color) !important;
  &:hover{
    background-color: var(--l-primary-tenant-hover-button) !important;
  }
}

.secondary-button{
  cursor: pointer !important;
  color: var(--l-secondary-tenant-color) !important;
  border-radius: 1.5rem !important;
  border: 1px solid var(--l-secondary-tenant-color, #222) !important;
  &:hover{
    background-color: var(--l-secondary-tenant-hover-button, #ECECEC) !important;
  }
}
.secondary-button-unselect{
  cursor: pointer !important;
  color: var(--l-grey-900) !important;
  border-radius: 1.5rem !important;
    border: 1px solid var(--l-grey-900) !important;
    &:hover{
      background-color: #dfdfdf !important;
    }
}

.tertiary-button{
  color: var(--l-tertiary-tenant-color) !important;
  text-decoration-line: underline !important;
  cursor: pointer !important;
  &:hover{
    color: var(--l-tertiary-tenant-color) !important;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--l-secondary-tenant-color)!important;
    background: var(--l-secondary-tenant-color)!important;

}
.p-checkbox .p-checkbox-icon path {
  stroke: white !important;
}

a {
    color: var(--l-grey-900) !important;
    text-decoration: none !important;
}
a:focus{
    color: var(--l-grey-900) !important;
}

.a-xs {
    color: var(--l-grey-500) !important;
}
.a-xs:hover{
    color: var(--l-grey-400) !important;

}

a:hover {
    color: var(--l-grey-400) !important;
}

::ng-deep p-button button {
    background-color: var(--l-primary-tenant-color) !important;
    color: var(--l-grey-000);
    border: none !important;
}

::ng-deep p-button button:hover {
    background-color: var(--l-primary-tenant-hover-button) !important;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: var( --l-grey-600) ;
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):focus .p-menuitem-text {
    color: var(--l-grey-900);
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: var(--l-grey-700);
}
.p-menuitem-link-active .p-menuitem-text
{
  font-weight: bold;
  color: var(--l-secondary-tenant-color) !important;
}
.p-menubar {
    border: none;
    background: transparent;
    height: 80px;
    padding: 0.5rem 0rem !important;
}
.p-menubar-button {
    display: none !important;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .15rem transparent;
}

/* header ends */

/* dropdown starts*/
.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0 transparent;
}
/* dropdown ends*/

/* button starts */
.p-button{
    background-color: var(--l-primary-tenant-color) !important;
    border-radius: 24px;
    border: none;
    color: var(--l-primary-tenant-button-text) !important;
    font-size: 12px;
    font-weight: 700;
}
/* TODO all deals button affected */
.p-button:enabled:hover{
  border-color:white !important
}
::ng-deep p-button:enabled:hover{
  border-color:white !important
}

.p-button:focus{
  border-radius: 1.5rem;
  border: 2px solid var(--grey-000, #FFF);
  background: var(--l-primary-tenant-color, #E3447C);
  box-shadow: 0px 0px 0px 2px #000;
}
/* button ends */

/* outlined button starts */
.p-button-outlined{

    background-color: var(--l-grey-000) !important;
    color: var(--l-secondary-tenant-color) !important;
    border: 1px solid var(--l-secondary-tenant-color) !important;
    border-radius: 1.5rem;

}
.p-button-outlined:hover{
    border: 1px solid var(--l-secondary-tenant-color) !important;
    border-radius: 1.5rem;
    background-color: var(--l-secondary-tenant-hover-button) !important;
}
/* outlined butotn ends */


/* dropdown starts */
.p-dropdown {
    border: none;
}

.p-dropdown.p-dropdown-label.p-placeholder {
    display: none;
}

.p-dropdown.p-dropdown-trigger:focus {
    border: none;
}

.p-dropdown-label {
    display: none;
}

.p-dropdown-items-wrapper::-webkit-scrollbar {
    width: 5px !important;
    background-color: var(--l-grey-400);
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--l-grey-600);
}

.p-dropdown-panel {
    border: 1px solid #EFEFEF !important;
    border-radius: 12px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--l-primary-tenant-color);
    background: none;

}

.p-dropdown-item {
    font-size: 14px;

}

.p-dropdown-item:hover {
    background: none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: none;
    color: unset;
}


/* dropdown starts */


