:root {
  --l-primary-50: #f1f7ff;
  --l-primary-100: #e7f5ff;
  --l-primary-200: #b2d2ff;
  --l-primary-300: #ace1ff;
  --l-primary-400: #71cbff;
  --l-primary-500: #1c6fe2;
  --l-primary-600: #0c5fd1;
  --l-primary-700: #0463b2;
  --l-primary-800: #0b3c66;
  --l-primary-900: #0e2941;

  --l-header-travel-color:#555555;
  --l-header-health-color:#555555;
  --l-header-shop-color:#555555;

  --l-primary-tenant-button-text: white;
  --l-primary-tenant-color:red;
  --l-primary-tenant-hover-button:pink;
  --l-primary-tenant-stats-color:#FFFAD9;


  --l-secondary-tenant-color: #d27000;
  --l-secondary-tenant-button-text: white;
  --l-secondary-tenant-hover-button:orange;

  --l-tertiary-tenant-color:green;

  --l-grey-100: #fdfafa;
  --l-grey-200: #dfdfdf;
  --l-grey-300: #b5b5b5;
  --l-grey-400: #9e9e9e;
  --l-grey-500: #888888;
  --l-grey-600: #6f6f6f;
  --l-grey-700: #555555;
  --l-grey-800: #303030;
  --l-grey-900: #222222;
  --l-grey-000: #ffffff;

}

.container-laasy {
  padding-right: 7.2rem;
  padding-left: 6.7rem;
  font-family: Helvetica,sans-serif !important;

  @media screen and (max-width: 1020px) {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  @media screen and (max-width: 600px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

/* Authentication form styling*/

.p-inputtext:enabled:focus{
  box-shadow:none!important;
  border: 1px solid var(--l-grey-900)!important;
}

.p-dialog {
  border: none !important;
  ::-webkit-scrollbar {
    width: 0.4rem !important;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
  }
}

.invalid-autocomplete {
  border: 2px solid var(--negative-500, #D63E47)!important;
  border-radius: 0.9rem;
}

.white-space-normal {
  white-space: normal !important;
}
