@import './responsive.scss';

.sm{
  display: none;
  @include xx-small{
    display: block;
  }
  @include x-small{
    display: block;
  }
  @include medium{
    display: none;
  }
}

.md{
  display: none;
  @include medium{
    display: block;
  }
}

.lg{
  display: none;
  @include large{
    display: block;
  }
}