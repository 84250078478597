@mixin xx-small {
  @media screen and (max-width: 323px) {
    @content;
  }
}

@mixin x-small {
  @media screen and (min-width: 324px) {
    @content;
  }
}

@mixin small {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin x-large {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin xx-large {
  @media screen and (min-width: 1400px) {
    @content;
  }
}